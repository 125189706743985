import React, { useEffect, useState } from 'react'
import axios from 'axios';
import BookCard from '../shared/BookCard'
import PhotoIcon from './icons/PhotoIcon'
import { convertToBase64 } from '../Utils/convertToBase64'
import { toast, Toaster } from "react-hot-toast";
import LoadingSpinner from '../shared/LoadingSpinner';
import { useNavigate } from 'react-router-dom';



const ViewProfile = () => {
  const navigate = useNavigate();
  const[loading,setLoading] = useState(false);
  const[previewImg,setPreviewImg] = useState("");
  const[books,setBooks] = useState([]);

   
    

    // Fetch user data from local storage
   const[userData,setUserData] = useState(null);

   useEffect(() => {
    const fetchUserData = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData || {};
  };

  /**
   * THIS CODE TEMPORARY FETCHES THE BOOKS UPLOADED BY THE USER
   */
   const fetchBooks = async() => {
     const url = process.env.REACT_APP_BOOK;
     try{
      const res = await fetch(url,{headers:{"Authorization":`Bearer ${localStorage.getItem("login_token")}`}});
      const resObj = await res.json();
       setBooks(resObj?.data?.books)
     }
     catch(err){
      toast.error(`Error gettings books: ${err?.message}`)
     } 
   }
     setUserData(fetchUserData());
     fetchBooks()
 }, []);




  /**
   * PROFILE IMAGE UPDATE CODE BEGINS
   */
  // Cloudinary file upload
  const cloudName = process.env.REACT_APP_CLOUD_NAME;
  const cloudinaryUploadPreset = process.env.REACT_APP_IMAGES_UPLOAD_PRESET;

  const uploadToCloudinary = async (file, resourceType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", cloudinaryUploadPreset);

    try {
      let apiKey = `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`;
      const response = await axios.post(apiKey, formData);
      const { secure_url } = response?.data;
      return secure_url;
    } catch (err) {
      toast.error(err.message);
    }
  };


 const handleChange = async(e) => {
    const file = e.target.files[0];
    const readFile = await convertToBase64(file)
    setPreviewImg(readFile)
 }


 const handleProfileUpdate = async(e) => {
  e.preventDefault();
  if(!previewImg){
    return toast.error("Please Select an image")
  }
  setLoading(true);
  const profileUrl =  await uploadToCloudinary( previewImg,"image");
  if(!profileUrl){
    setLoading(false)
    return;
  }

  const data = {
    profileImg: profileUrl,
  }
  console.log(profileUrl)
  try{
   const url = process.env.REACT_APP_UPDATE_USER;
   const params = {
    method:"PUT",
    headers:{
      "Content-Type":"application/json",
     "Authorization":`Bearer ${localStorage.getItem("login_token")}`
    },
    body:JSON.stringify(data)
   }
   const response = await fetch(url, params);
   const resObj = await response.json();
   
   if(!response) {
    throw new Error(resObj?.message)
   }
   console.log(userData)
   let editUserData = {...userData, profileImg:profileUrl};
      localStorage.setItem("userData",JSON.stringify(editUserData));
      toast.success(resObj?.message);

      // wait some  seconds and navigate to dashboard
      setTimeout(()=>{
        navigate("/dashboard");
        window.location.href = '/dashboard';
      },2000)
  }
  catch(err){
    toast.error(`Error updating profile image : ${err.message}`)
  }
  finally{
    setLoading(false)
  }
 }
  return (
    <div className='mt-20'>
        <div className="profile-details mb-[49px] lg:mb-[64px]">
        <div className="flex flex-col md:flex-row md:items-center md:gap-x-[24px]">
          <div className="flex-item mb-[16px]">
            <div className="inline-block w-[120px] relative overflow-hidden z-10 h-[120px] rounded-[50%] group">
              {/* React hot Toast */}
               <Toaster position="top-center" reverseOrder={false}></Toaster>
               <input className='hidden' type="file" accept='.jpg, .jpeg, .png' id='profile' onChange={handleChange} />
               <label htmlFor="profile">
               <img className='inline-block h-full object-cover rounded-[50%]' src={previewImg || userData?.profileImg} alt="profile -image" />
              <span 
              className="inline-block hidden group-hover:flex justify-center items-center h-[100%] 
              w-[100%] absolute top-0 left-0 rounded-[50%] bg-white z-20 cursor-pointer"
              style={{background:`linear-gradient(0deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.32) 100%)`}}
              >
                <PhotoIcon/>
              </span>
                 </label>
              {/* <span 
              className="inline-block h-[100%] 
              w-[100%] absolute top-0 left-0 border border-red-900 rounded-[50%] bg-white z-40"
              style={{background:`linear-gradient(0deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.32) 100%),url(${'/assets/photoicon.svg'}) lightgray 50% /contain no-repeat `}}
              >
              </span> */}
            </div>
          </div>
          <div className="flex-item">
            <p className='capitalize font-[600] text-[1rem] lg:text-[1.5rem]'>{userData?.fullName}</p>
            <p className='lg:text-[1rem] mb-[8px] text-[#8e8e8e]'>@{userData?.email?.match(/^[^@]+/)[0]}</p>
            <div className="btn-container">
                <button disabled={loading} onClick={handleProfileUpdate} className={`cta-btn px-[24px]`} style={{background:`${loading ? "#f2f2f2" :""}`}}>
                  {loading ? <LoadingSpinner/> : "update profile"}
                  </button>
            </div>
          </div>
         </div>
        </div>
        <div className="uploaded-materials">
            <div className="btn-container mb-[32px]">
                <button className='cta-btn' style={{textTransform:"initial",background:"#f2f2f2",cursor:"initial"}}>your Materials</button>
            </div>
            {/* Uploaded materials */}
            {
             books?.length === 0 ? (
              <h4>No Uploaded Materials !</h4>
             )
             :
             (<BookCard books={books}/> )
            }
        </div>      
    </div>
  )
}

export default ViewProfile