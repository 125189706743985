import React, { useState } from 'react'
import ModalWrapper from './ModalWrapper'
import LevelIcon from '../Dashboard/icons/LevelIcon'

const FilterBookModal = ({title,closeModal,closeModalIcon}) => {


  return (
    <ModalWrapper>
        <div className='add-material-container overflow-y-auto lg:overflow-y-hidden webkit-scrollbar h-[484px] rounded-[12px] bg-white p-[28px] w-[95%] lg:w-[989px]'>
        <div className="modal-header flex m-[24px] justify-between items-start">
        <h4>{title}</h4>
        <span 
         className='icon-container p-[0.1rem] cursor-pointer h-[27px] w-[27px] border border-[#a5a5a5] rounded-[50%]'  
         onClick={closeModal}>
         {closeModalIcon}
        </span>
        </div>

        <div className="lg:flex">
          <div className="flex-item flex flex-row lg:flex-col">
            <div className="inner-flex-item btn-container">
              <button className='cta-btn'>
                <span className='inline-block'><LevelIcon/></span>
                level
                </button>
            </div>
            {/* <div className="inner-flex-item"></div> */}
          </div>
          <div className="flex-item">
            <p>level</p>

          </div>
        </div>

    </div>
    </ModalWrapper>
  )
}

export default FilterBookModal