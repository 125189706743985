import React from 'react'

function LectureNotes() {
  return (
    <div>
         <p>No Uploaded Lecture Notes Yet</p>
    </div>
  )
}

export default LectureNotes