import React from 'react'

function PastQuestions() {
  return (
    <div>
         <p>No Uploaded Past Questions Yet</p>
    </div>
  )
}

export default PastQuestions