import React, { useState } from 'react';
import StudyShareIcon from '../icons/StudyShareIcon';
import StudyShareIndex from '../StudyShare/StudyShareIndex';
import TabSelector from '../../TabSelector/TabSelector';
import TextBooks from '../StudyShare/TextBooks';
import PastQuestions from '../StudyShare/PastQuestions';
import LectureNotes from '../StudyShare/LectureNotes';
import Fillers from '../StudyShare/Fillers';
import FilterPage from './FilterPage';
import FilterIcon from '../icons/FilterIcon';
import Summaries from '../StudyShare/Summaries';
import FilterBookModal from '../../Modals/FilterBookModal';
import CloseModalIcon from '../icons/CloseModalIcon';

function StudyShare() {
  const [activeTab, setActiveTab] = useState("all");

  // Default tabs without filter
  const baseTabs = ["all", "text books", "past questions", "lecture notes", "fillers","summaries"];
  
  // Include the "filter" tab only when "text books" is the active tab
  const tabs = activeTab === "text books" || activeTab === "filter"  ? [...baseTabs, "filter"] : baseTabs;
  
  // Handle click for each Tab
  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseFilter = () => {
    setActiveTab("text books")
  }

  return (
    <div className='studyshare-container mt-20'>
      <h1 className='icon-container'>
        <StudyShareIcon />
      </h1>
      <h5>studyshare</h5>

      {/* Render TabSelector with conditionally included tabs */}
      <TabSelector
        tabs={tabs}
        handleClick={handleClick}
        activeTab={activeTab}
      />

      <div className='display-activetab-details'>
        {/* Show component based on the active tab */}
        {activeTab === "all" && <StudyShareIndex />}
        {activeTab === "text books" && <TextBooks />}
        {activeTab === "past questions" && <PastQuestions />}
        {activeTab === "lecture notes" && <LectureNotes />}
        {activeTab === "fillers" && <Fillers  /> }
        {activeTab === "filter" && 
        <FilterBookModal 
        title={"Filter by"}
        closeModalIcon = <CloseModalIcon/>
        closeModal = {handleCloseFilter}
        />}
        {activeTab === "summaries" && <Summaries />}
      </div>
    </div>
  );
}

export default StudyShare;
