import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import HomeIcon from "./icons/HomeIcon";
import CopyIcon from "./icons/CopyIcon";

function DashboardHome() {
  const [userData, setUserData] = useState(null);
  const [copy, setCopy] = useState(false);
  const [groupLink] = useState(
    "https://chat.whatsapp.com/IcoTBnV0rcM2KJjeqr8wFZ",
  );

  // Countdown state
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const[pastCount,setPastCount] = useState(null);
  useEffect(() => {
    const countDownDate = new Date("nov 15, 2024 20:00:00").getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown object
      setCountdown({ days, hours, minutes, seconds });
      setPastCount(1)

      // Clear interval when countdown is finished
      if (distance < 0) {
        setPastCount(0)
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchUserData = () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      return userData || {};
    };
    setUserData(fetchUserData());
  }, []);

  // Handle copy
  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => setCopy(false), 1000); // Reset copy state after 2 seconds
  };


  return (
    <div className="dashboard-home mt-20">
      <h1 className="icon-container">
        <HomeIcon />
      </h1>
      <h5>Home</h5>
      <div className="btn-container mb-4">
        <button className="cta-btn active">Live Webinar</button>
      </div>

      <div
        className="webinar-countdown min-h-[95px] lg:min-h-[181px] mb-[24px] rounded-[12px] p-2 lg:py-4"
        style={{
          background: `url(${`/assets/home-banner-bg.svg`}) no-repeat center/cover #f2f2f2`,
        }}
      >
        {
          !pastCount ? (
          <div className="countdown-wrapper flex justify-center items-center">
          <div className="m-auto basis-[527px]">
            <h4>Webinar count down Paused</h4>
          </div>
          </div>
          )
          :
          (
          <div className="countdown-wrapper flex justify-center items-center">
          <div className="m-auto basis-[104px] md:basis-[527px]">
          <h6 className="text-[0.597rem] md:text-[1.25rem] font-[600] mt-2 mb-4 p-2">webinar countdown</h6>
          <figure className="countdown-timings flex gap-[8px] md:gap-[14px]">
          <div>
              <h5 className="days">{countdown?.days}</h5>
              <span>DAYS</span>
            </div>
          <div>
              <h5 className="hours">{countdown?.hours}</h5>
              <span>HOURS</span>
            </div>
          <div>
              <h5 className="minutes">{countdown?.minutes}</h5>
              <span>MINUTES</span>
            </div>
            <div>
              <div className="seconds-container">
              <h5 className="seconds">{countdown?.seconds}</h5>
              </div>
              <span>SECONDS</span>
            </div>
          </figure>
          </div>
        </div>
          )
        }
      </div>

      <section>
        <h4 className="mb-[24px]">Next Webinar</h4>
        <div className="next-webinar flex flex-col lg:flex-row gap-6">
          <div className="flex-item lg:basis-[48%] mb-8 lg:mb-0">
            <div className="img-container webinar-banner rounded-[12px]">
              <img src={`/assets/shanon-webinar.png`} alt="webinar banner" />
            </div>
          </div>
          <div className="flex-item lg:basis-[48%]">
            <h4 className="title text-[0.875rem] text-[#6f6f6f]">Title</h4>
            <p className="title text-[1.25rem] md:text-[2rem] font-[600]">
              Married <span className="italic">in</span> {" "}
              <strong className="text-[var(--green-color)]"> SCHOOL ,</strong>
               Single <span className="italic">at</span> {" "}
              <strong className="text-[var(--green-color)]"> HOME </strong>
            </p>
            {/* <p className="title text-[1.25rem] md:text-[2rem] font-[600]">
             
            </p> */}
            <div className="speaker-details mb-[8px]">
              <h5 className="font-[500] text-[1rem]">
                Speaker:{" "}
                <span className="text-[#808080]">Shanon Ebekee </span>
              </h5>
            </div>

            <div className="webinar-details">
              <div className="event-timings lg:flex gap-8">
                <h5 className="font-[500]">
                  <span className="icon-container mr-4">
                    <img src={`/assets/date-icon.svg`} alt="date" />
                  </span>
                  15th November, 2024
                </h5>
                <h5 className="font-[500] mb-2">
                  <span className="icon-container mr-4">
                    <img src={`/assets/time-icon.svg`} alt="time" />
                  </span>
                  8:00 PM
                </h5>
              </div>
              <div className="flex flex-wrap gap-[4%] mb-8 items-center">
                <h5 className="mb-0">Live on WhatsApp</h5>
                <input
                  type="text"
                  readOnly
                  className="text-sm basis-[48%] lg:basis-[68%]"
                  name="copy"
                  value={groupLink}
                  id="link"
                />
                <CopyToClipboard text={groupLink} onCopy={handleCopy}>
                  <span
                    className={`copy-icon cursor-pointer inline-block flex align-middle items-center ${copy ? "copied" : ""}`}
                  >
                    {copy ? "Copied" : <CopyIcon />}
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h4 className="mb-[24px]">Previous Webinars</h4>
        {/* <div className="previous-webinar max-w-[220px] md:min-w-[680px] lg:min-w-[1148px] overflow-auto">
          <div className="block-wrapper mb-8">
            {Array(3).fill().map((_, index) => (
              <figure className="block-item" key={index}>
                <div className="img-container rounded-[12px]">
                  <img src={`/assets/webinar-banner.svg`} alt="previous webinar" />
                </div>
              </figure>
            ))}
          </div>
        </div> */}
        <div className="previous-webinar overflow-auto">
          <div className="block-wrapper mb-8">
            {Array(3)
              .fill()
              .map((_, index) => (
                <figure className="block-item" key={index}>
                  <div className="img-container rounded-[12px]">
                    <img
                      src={`/assets/webinar-banner.svg`}
                      alt="previous webinar"
                    />
                  </div>
                </figure>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardHome;
