import React, { useEffect, useState } from 'react'
import AddMaterialCard from '../../shared/AddMaterialCard';
import AddMaterialIcon from '../icons/AddMaterialIcon';
import AddMaterialModal from '../../Modals/AddMaterialModal';
import CloseModalIcon from '../icons/CloseModalIcon';
import { Toaster,toast } from 'react-hot-toast';
import BookCard from '../../shared/BookCard';


function StudyShareIndex() {
  const[modal,setModal] = useState(false);
  const[contributesNo,setContributesNo] =  useState(0);
  const[books,setBooks] = useState([])

  useEffect(()=>{
    /**
   * THIS CODE TEMPORARY FETCHES THE BOOKS UPLOADED BY THE USER
   */
   const fetchBooks = async() => {
    const url = process.env.REACT_APP_BOOKS;
    try{
     const res = await fetch(url,{headers:{"Authorization":`Bearer ${localStorage.getItem("login_token")}`}});
     const resObj = await res.json();
      setBooks(resObj?.data?.books)
    }
    catch(err){
     toast.error(`Error gettings books: ${err?.message}`)
    } 
  }

   const { noOfContributions } = JSON.parse(localStorage.getItem("userData"));
   setContributesNo(noOfContributions);
   fetchBooks()
  },[])

  // Hnadle Open and Closing of Modal
  const handleModal = () => {
    setModal(prev => ! prev)
  }

  const foooterBtns = [
    {
      label:"Cancel",
      type:"cancel"
    },
    {
      label:"Publish",
      type:"submit"
    },
  ]
  return (
    <div>
      {/* Modals */}
      {
        modal && (
        <AddMaterialModal 
        title={"Study share"}
        closeModal={handleModal}
        closeModalIcon={<CloseModalIcon/>}
        footerBtns={foooterBtns}
        />)
      }
      <h6 className='font-[600] text-[18px] mb-[16px]'>Recent uploads</h6>
      {/* React hot Toast */}
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <AddMaterialCard 
      contributes={contributesNo}
      text={"Add material"}
      addMaterialIcon={<AddMaterialIcon/>}
      openMaterialModal={handleModal}
      />
      <BookCard books={books}/>
    </div>
  )
}

export default StudyShareIndex