import React from 'react'
import ModalWrapper from './ModalWrapper'
import StudyShareForm from '../Dashboard/StudyShare/StudyShareForm'
// import DragAndDrop from '../Dashboard/StudyShare/DragAndDrop'
import UploadFileButtons from '../Dashboard/StudyShare/UploadFileButtons'

function AddMaterialModal({title,closeModal,closeModalIcon,footerBtns=[]}) {
  // const [stackedBooks,setStackedBooks]  = useState([]);

  // console.log(stackedBooks)
  return (
    <ModalWrapper>
     <div className='add-material-container overflow-y-auto lg:overflow-y-hidden webkit-scrollbar h-[484px] rounded-[12px] bg-white p-[28px] w-[95%] lg:w-[989px]'>
        <div className="modal-header flex m-[24px] justify-between items-start">
        <h4>{title}</h4>
        <span 
         className='icon-container p-[0.1rem] cursor-pointer h-[27px] w-[27px] border border-[#a5a5a5] rounded-[50%]'  
         onClick={closeModal}>
         {closeModalIcon}
        </span>
        </div>

        {/* add material body*/}
        {/* <div className="modal-body lg:grid grid-cols-[97px,1fr] lg:gap-[40px]">
            <div className="grid-items mb-[32px]">
                <UploadFileButtons/>
            </div>
            <div className="grid-items studyshare-form overflow-y-auto">
             <StudyShareForm footerBtns={footerBtns} closeModal={closeModal}/>
            </div>
        </div> */}
         {/* <div className="grid-items studyshare-form overflow-y-auto"> */}
         <StudyShareForm footerBtns={footerBtns} closeModal={closeModal}/>
    </div>
    </ModalWrapper>
  )
}

export default AddMaterialModal