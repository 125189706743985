import React, { useEffect, useState } from "react";
import MenuBarIcon from "./icons/MenuBarIcon";
import { Link, NavLink } from "react-router-dom";
import ContributorsIcon from "./icons/ContributorsIcon";
import CommunityIcon from "./icons/CommunityIcon";
import StudyShareActiveIcon from "./icons/StudyShareActiveIcon";
import StudyShareIcon from "./icons/StudyShareIcon";
import ServicesActiveIcon from "./icons/ServicesActiveIcon";
import ServicesIcon from "./icons/ServicesIcon";
import HomeIconActive from "./icons/HomeIconActive";
import HomeIcon from "./icons/HomeIcon";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import ArrowDownActive from "./icons/ArrowDownActive";
import ArrowUpIcon from "./icons/ArrowUpIcon";
import GradePointCalcIcon from "./icons/GradePointCalcIcon";
import PassportGenIcon from "./icons/PassportGenIcon";
import HelpIcon from "./icons/HelpIcon";
import FeedbackIcon from "./icons/FeedbackIcon";
import SettingsActiveIcon from "./icons/SettingsActiveIcon";
import SettingsIcon from "./icons/SettingsIcon";
import LogoutIcon from "./icons/LogoutIcon";
import CommunityActiveIcon from "./icons/CommunityActiveIcon";
import HelpActiveIcon from "./icons/HelpActiveIcon";
import FeedBackActiveIcon from "./icons/FeedBackActiveIcon";
import ProfileModal from "../Modals/ProfileModal";
import CloseModalIcon from "./icons/CloseModalIcon";
import { toast, Toaster } from "react-hot-toast";


function DashboardHeader({ profileImage ,handleLogout,userData}) {
  const [subsideMenu, setSubsideMenu] = useState(false);
  const [activeList, setActiveList] = useState(false);
  const [showProfile,setShowProfile] = useState(false);
  const [showContributor,setShowContributor] = useState(false);
  const[contributors,setContributors] = useState([]);
  const[loading,setLoading] = useState(false)


  // Fetch all contributors
 useEffect(()=>{
  const fetchContributors = async() => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BOOKS}/all-contributors`;
    try{
     const res = await fetch(url,{headers:{"Authorization":`Bearer ${localStorage.getItem("login_token")}`}});
     const resObj = await res.json();
      setContributors(resObj?.data?.getContributedUsers);
    }
    catch(err){
     toast.error(`Error gettings books: ${err?.message}`)
    }  
    finally{
      setLoading(false)
    }
   }
   fetchContributors()
 },[])



  // Handle sub side menu
  const handleSubsideMenu = () => {
    setSubsideMenu((prev) => !prev);
  };

  // handle click
  const handleListClick = () => {
    setActiveList((prev) => !prev);
  };

  // Handle profile show
  const handleShowProfile = () => {
    setShowProfile(prev => !prev)
  }

  // Handle contributors
  const handleShowContributors = () => {
    setShowContributor(prev => !prev)
  }
  // console.log(contributors)
  return (
    <header className="dashboard-header">
      {/* Modals */}
      {
        //imported modals goes here
      }
      {/* React hot Toast */}
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <section className="header-section min-h-[45px] lg:min-h-[64px]">
        <nav className="nav-wrapper">
          <ul className="nav-list flex lg:gap-[0.75rem] justify-between items-center">
            <li
              className="list-item cursor-pointer menu-bar"
              onClick={handleSubsideMenu}
            >
              <MenuBarIcon />
            </li>
            <li className="list-item hidden lg:block">
              <div className="flex-wrapper">
                <Link className="flex items-center" to="/dashboard">
                  <img src={`/assets/1stclassmaterial-logo.svg`} alt="logo" />
                  <span className="logo-text">1stClassMaterial</span>
                </Link>
              </div>
            </li>
           
            <li className="relative list-item ml-auto cursor-pointer group">
              <div 
              onClick={handleShowContributors}
              className="contributors flex items-center gap-2 border border-[#e7e7e7] p-1 rounded-[25px]">
                <p className="icon-container w-[33px] h-[33px] rounded-[50%] bg-[#ffe500] text-center">
                  <span className="inline-block align-middle">
                    <ContributorsIcon />
                  </span>
                </p>
                <span className="text-[0.875rem] font-[500] hidden lg:block">Contributors</span>
              </div>
              {/* contributors section */}
              <div className={`contributors webkit-scrollbar fixed z-50 right-6 top-20 border border-[#E7E7E7] shadow-[0_0px_12px_0px_rgba(218,218,218,0.5)] 
                transition-all duration-300 ease-in-out min-w-[313px] overflow-auto h-[100vh] bg-white rounded-[12px] p-1 ${showContributor ? "translate-x-[0%]" : 'translate-x-[150%]'}`}>
                <div className="contributor-header pl-[17px] pr-[16px] py-[12px] flex justify-between">
                  <div className="flex-item">
                    <h6 className="font-[600] text-[1rem]">Contributor board</h6>
                  </div>
                  <div className="flex-item">
                    <span 
                    onClick={handleShowContributors}
                    className="icon-container inline-block border border-[#a5a5a5] rounded-[50%]">
                      <CloseModalIcon/>
                    </span>
                  </div>
                </div>
                <div className="contributor-body h-[643px]">
                {
                loading ? 
                (<p className="text-center">Loading contributors..</p>) 
                  :
                (
                 contributors.map(contributor => (
                    <div key={contributor?._id}>
                      <div className="flex-item bg-[#f2f2f2] rounded-[12px] px-[6px] py-[4px]  flex justify-between  mb-[12px]">
                        <div className="flex-item flex gap-x-[17px] items-center">
                          <div className="img-container h-[48px] w-[48px] rounded-full">
                            <img className="h-full" src={contributor?.profileImg} alt={contributor?.fullName} />
                          </div>
                          <p className="font-semibold">{contributor?.fullName.split(" ")[0]}</p>
                        </div>
                        <div className="flex-item ml-auto">
                          <p className="font-semibold text-right">{contributor?.noOfContributions}</p>
                          <p className="text-[#909090]">CONTRIBUTES</p>
                        </div>
                      </div>
                    </div>
                   ))
                )
                }
                </div>
              </div>
            </li>
            <li className="list-item relative">
              <div 
              className="img-container cursor-pointer border border-grey-900 rounded-[50%] h-[38px] w-[38px]"
              onClick={handleShowProfile}
              // onMouseEnter={handleShowProfile}
              >
                <img className="inline-block h-full" src={profileImage} alt={profileImage} />
              </div>
                {/* display user profile  details */}
                {showProfile ? <ProfileModal 
                userData={userData} 
                handleShowProfile={handleShowProfile}
                handleLogout={handleLogout}/> : null}
            </li>
          </ul>
        </nav>

        {/* Sub side menu */}
        <div
          className={subsideMenu ? `subside-menu-wrapper` : ``}
          onClick={handleSubsideMenu}
        >
          <div
            className={
              subsideMenu
                ? `subside-menu-section active`
                : `subside-menu-section`
            }
          >
            <nav className="nav-wrapper mt-[3.875rem]">
              <ul className="list-item">
                <li>
                  <Link className="flex items-center" to="/dashboard">
                    <div className="img-container w-[35px] h-[35px]">
                      <img
                        src={`/assets/1stclassmaterial-logo.svg`}
                        alt="logo"
                      />
                    </div>
                    <span className="logo-text">1stClassMaterial</span>
                  </Link>
                </li>
                <li>
                  <NavLink
                    to="/dashboard"
                    end
                    //   className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    {({ isActive }) => (
                      <div className="flex flex-row items-center gap-2">
                        <span className="nav-icon">
                          {isActive ? <HomeIconActive /> : <HomeIcon />}
                        </span>
                        <span>home</span>
                      </div>
                    )}
                  </NavLink>
                </li>
                <li>
                  <div
                    className="flex flex-row items-center gap-2 p-3"
                    onClick={() => {
                      handleSubsideMenu();
                      handleListClick();
                    }}
                  >
                    <span className="nav-icon">
                      <ServicesIcon />
                    </span>
                    <span className="mr-auto">Services</span>
                    <span className="arrow-icon">
                      {activeList ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </span>
                  </div>
                  <ul
                    className={`pl-8 ${activeList ? "dropdown-menu active" : "dropdown-menu"}`}
                  >
                    <div className="dropdown">
                      <li className="dropdown-list">
                        <NavLink to="calculate-gp">
                          <div className="flex flex-row items-center gap-2">
                            <span className="nav-icon">
                              <GradePointCalcIcon />
                            </span>
                            <span>GP calculator</span>
                          </div>
                        </NavLink>
                      </li>
                      <li className="dropdown-list">
                        <NavLink to="passport-generator">
                          <div className="flex flex-row items-center gap-2">
                            <span className="nav-icon">
                              <PassportGenIcon />
                            </span>
                            <span>passport generator</span>
                          </div>
                        </NavLink>
                      </li>
                    </div>
                  </ul>
                </li>
                <li>
                  <NavLink to="studyshare">
                    {({ isActive }) => (
                      <div className="flex flex-row items-center gap-2">
                        <span className="nav-icon">
                          {isActive ? (
                            <StudyShareActiveIcon />
                          ) : (
                            <StudyShareIcon />
                          )}
                        </span>
                        <span>studyshare</span>
                      </div>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="community">
                   {
                    ({isActive}) => (
                      <div className="flex flex-row items-center gap-2">
                      <span className="nav-icon">
                        {isActive ? <CommunityActiveIcon/> : <CommunityIcon />}
                      </span>
                      <span>community</span>
                    </div>
                    )
                   }
                  </NavLink>
                </li>
                <hr className="mb-[1rem]" />
                <li>
                  <NavLink to="settings">
                    {({ isActive }) => (
                      <div className="flex flex-row items-center gap-2">
                        <span className="nav-icon">
                          {isActive ? <SettingsActiveIcon /> : <SettingsIcon />}
                        </span>
                        <span>settings</span>
                      </div>
                    )}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="help">
                    {
                      ({isActive}) =>  (
                      <div className="flex flex-row items-center gap-2">
                      <span className="nav-icon">
                        {isActive ? <HelpActiveIcon/> : <HelpIcon />}
                      </span>
                      <span>help</span>
                    </div>
                      )
                    }
                  </NavLink>
                </li> */}
                <li className="mb-[11.40rem]">
                  <NavLink to="feedback">
                    {
                      ({isActive}) => (
                        <div className="flex flex-row items-center gap-2">
                        <span className="nav-icon">
                          {isActive ? <FeedBackActiveIcon/> : <FeedbackIcon />}
                        </span>
                        <span> feedback</span>
                      </div>
                      )
                    }
                  </NavLink>
                </li>
                <li className="mb-[11.40rem]">
                  <Link to="/login" onClick={handleLogout}>
                    <div className="flex flex-row items-center gap-2">
                      <span className="nav-icon">
                        <LogoutIcon />
                      </span>
                      <span className="text-[#f3514b]"> logout</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </header>
  );
}

export default DashboardHeader;
